import httpClient from '../utils/axios'

const getAbsenceDataFromApiAsync = async (userId) => {
    return httpClient
        .get(
            `${window.config.USER_API_URL}/${window.config.USER_API_VERSION}/Absence/${userId}`
        )
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error)
            return null
        })
}

export default {
    getAbsenceDataFromApiAsync,
}
