import httpClient from '../utils/axios'
import {
    handleServiceError,
    handleServiceResponse,
} from '../utils/serviceResponseHelper'
import { enqueueSnackbar } from '../actions/notification.actions'
import store from '../store'

const defaultDataMap = [
    {
        name: 'Personal Details',
        orderBy: 0,
        fields: [
            { name: 'title', label: 'Title', dataType: 'string', orderBy: 0 },
            {
                name: 'firstName',
                label: 'Forename',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'firstNamePreferred',
                label: 'Known As',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'lastName',
                label: 'Surname',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'dateOfBirth',
                label: 'Date of Birth',
                dataType: 'date',
                orderBy: 0,
            },
        ],
    },
    {
        name: 'Contact Details',
        orderBy: 0,
        fields: [
            {
                name: 'phone',
                label: 'Phone Number',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'altPhone',
                label: 'Alternate Phone Number',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'email',
                label: 'Email Address',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'address1',
                label: 'Address Line 1',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'address2',
                label: 'Address Line 2',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'address3',
                label: 'Address Line 3',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'address4',
                label: 'Address Line 4',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'postCode',
                label: 'Post Code',
                dataType: 'string',
                orderBy: 0,
            },
        ],
    },
    {
        name: 'Employment Details',
        orderBy: 0,
        fields: [
            {
                name: 'staffId',
                label: 'Employee Number',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'position',
                label: 'Position',
                dataType: 'string',
                orderBy: 0,
            },
            { name: 'grade', label: 'Grade', dataType: 'string', orderBy: 0 },
            {
                name: 'startDate',
                label: 'Start Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'continuousServiceDate',
                label: 'Continuous Service Date',
                dataType: 'date',
                orderBy: 0,
            },
            { name: 'hours', label: 'Hours', dataType: 'decimal', orderBy: 0 },
            {
                name: 'noticePeriod',
                label: 'Notice Period',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'probationDate',
                label: 'Probation Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'endDate',
                label: 'Leave Date',
                dataType: 'date',
                orderBy: 0,
            },
            {
                name: 'endDateReason',
                label: 'Leave Reason',
                dataType: 'string',
                orderBy: 0,
            },
            {
                name: 'isManager',
                label: 'Is a Manager?',
                dataType: 'bool',
                orderBy: 0,
            },
        ],
    },
]
const getEmployeeDataFromApiAsync = async (userId) => {
    var url = `${window.config.USER_API_URL}/${window.config.USER_API_VERSION}/User/UserProfile/${userId}`
    return httpClient
        .get(url)
        .then((response) => {
            var data = handleServiceResponse(response)
            if (!data.dataMap) {
                data.dataMap = defaultDataMap
            }
            return data
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.errorMessage
            store.dispatch(
                enqueueSnackbar({
                    message: errorMessage || 'Could not fetch employee data',
                    options: {
                        variant: 'warning',
                    },
                })
            )
            return handleServiceError(error)
        })
}

const updateEmployeeUserCommentAsync = async (userId, userComments) => {
    var url = `${window.config.USER_API_URL}/${window.config.USER_API_VERSION}/User/UpdateUserComments/${userId}`
    return httpClient
        .patch(url, JSON.stringify({ userComments: userComments }), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            store.dispatch(
                enqueueSnackbar({
                    message: `Saved`,
                    options: {
                        variant: 'success',
                    },
                })
            )
            return handleServiceResponse(response)
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.errorMessage
            store.dispatch(
                enqueueSnackbar({
                    message: errorMessage || 'Could not update user comments',
                    options: {
                        variant: 'warning',
                    },
                })
            )
            return handleServiceError(error)
        })
}

const getEmployeesNamesAsync = async (employeeIds) => {
    const url = `${window.config.USER_API_URL}/${window.config.USER_API_VERSION}/User/Names`
    const { data } = await httpClient.post(url, JSON.stringify(employeeIds), {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}

export default {
    getEmployeeDataFromApiAsync,
    updateEmployeeUserCommentAsync,
    getEmployeesNamesAsync,
}
