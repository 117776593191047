import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'
import { NavigationProvider } from './contexts/navigation'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <NavigationProvider>
                <App />
            </NavigationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
