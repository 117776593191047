import { combineReducers } from 'redux'
import employeeInformationReducer from './employeeinfo.reducer'
import employeeAbsenceReducer from './employeeAbsenceReducer'
import employeeCaseHistoryReducer from './employeecasehistory.reducer'
import participantCaseHistoryReducer from './participantcasehistory.reducer'
import eventHistoryReducer from './eventHistory.reducer'
import authReducer from './auth.reducer'
import notificationReducer from './notification.reducer'
import brandingReducer from './branding.reducer'
import serviceLabelReducer from './serviceLabel.reducer'

const rootReducer = combineReducers({
    employeeInformation: employeeInformationReducer,
    employeeAbsence: employeeAbsenceReducer,
    employeeCaseHistory: employeeCaseHistoryReducer,
    participantCaseHistory: participantCaseHistoryReducer,
    eventHistory: eventHistoryReducer,
    auth: authReducer,
    notifications: notificationReducer,
    branding: brandingReducer,
    serviceLabel: serviceLabelReducer,
})

export default rootReducer
