import {
    GET_BRANDING_FAILURE,
    GET_BRANDING_SUCCESS,
    GET_BRANDING_REQUEST,
} from './types'
import brandingService from '../services/branding.service'

export function getServiceBranding() {
    return async (dispatch) => {
        dispatch(serviceBrandingRequest())
        try {
            const serviceBranding = await brandingService.getServiceBranding()
            dispatch(serviceBrandingSuccess(serviceBranding.json || null))
        } catch (error) {
            dispatch(serviceBrandingFailure())
        }
    }
    function serviceBrandingRequest() {
        return {
            type: GET_BRANDING_REQUEST,
        }
    }

    function serviceBrandingSuccess(response) {
        return {
            type: GET_BRANDING_SUCCESS,
            payload: response,
        }
    }

    function serviceBrandingFailure() {
        return {
            type: GET_BRANDING_FAILURE,
        }
    }
}
