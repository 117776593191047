import axios from 'axios'
import { getAccessToken, signOutRedirect } from '../services/auth.service'
import store from '../store'
import { enqueueSnackbar } from '../actions/notification.actions'

const instance = axios.create()

const requestHandler = async (request) => {
    try {
        const token = await getAccessToken()
        request.headers.Authorization = token
        return Promise.resolve(request)
    } catch (error) {
        return Promise.reject(error)
    }
}

const responseSuccessHandler = (response) => {
    return response
}

const responseErrorHandler = async (error) => {
    // network error
    if (!error.response) {
        store.dispatch(
            enqueueSnackbar({
                message: `Could not complete request to ${error.config.url}`,
                options: {
                    variant: 'error',
                },
            })
        )
    } else if (error.response.status === 401) {
        store.dispatch(
            enqueueSnackbar({
                message: `Unauthorized request`,
                options: {
                    variant: 'warning',
                },
            })
        )
        await signOutRedirect()
    }
    return Promise.reject(error)
}

instance.interceptors.request.use((request) => requestHandler(request))

instance.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
)

export default instance
