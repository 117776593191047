//EMPLOYEE INFORMATION
export const EMPLOYEE_INFO_REQUEST = 'EMPLOYEE_INFO_REQUEST'
export const EMPLOYEE_INFO_SUCCESS = 'EMPLOYEE_INFO_SUCCESS'
export const EMPLOYEE_INFO_FAILURE = 'EMPLOYEE_INFO_FAILURE'
export const UPDATE_USER_COMMENT_REQUEST = 'UPDATE_USER_COMMENT_REQUEST'
export const UPDATE_USER_COMMENT_SUCCESS = 'UPDATE_USER_COMMENT_SUCCESS'
export const UPDATE_USER_COMMENT_FAILURE = 'UPDATE_USER_COMMENT_FAILURE'

//EMPLOYEE ABSENCE
export const EMPLOYEE_ABSENCE_REQUEST = 'EMPLOYEE_ABSENCE_REQUEST'
export const EMPLOYEE_ABSENCE_SUCCESS = 'EMPLOYEE_ABSENCE_SUCCESS'
export const EMPLOYEE_ABSENCE_FAILURE = 'EMPLOYEE_ABSENCE_FAILURE'

//CASE HISTORY
export const EMPLOYEE_CASE_REQUEST = 'EMPLOYEE_CASE_REQUEST'
export const EMPLOYEE_CASE_SUCCESS = 'EMPLOYEE_CASE_SUCCESS'
export const EMPLOYEE_CASE_FAILURE = 'EMPLOYEE_CASE_FAILURE'
export const PARTICIPANT_CASE_REQUEST = 'PARTICIPANT_CASE_REQUEST'
export const PARTICIPANT_CASE_SUCCESS = 'PARTICIPANT_CASE_SUCCESS'
export const PARTICIPANT_CASE_FAILURE = 'PARTICIPANT_CASE_FAILURE'

//EVENT HISTORY
export const EVENT_HISTORY_REQUEST = 'EVENT_HISTORY_REQUEST'
export const EVENT_HISTORY_SUCCESS = 'EVENT_HISTORY_SUCCESS'
export const EVENT_HISTORY_FAILURE = 'EVENT_HISTORY_FAILURE'
export const EVENT_HISTORY_EXTRA_INFO_SUCCESS =
    'EVENT_HISTORY_EXTRA_INFO_SUCCESS'

// AUTH TYPES
export const STORE_USER = 'STORE_USER'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'

export const SERVICE_ACCESS_REQUEST = 'SERVICE_ACCESS_REQUEST'
export const SERVICE_ACCESS_SUCCESS = 'SERVICE_ACCESS_SUCCESS'
export const SERVICE_ACCESS_FAILURE = 'SERVICE_ACCESS_FAILURE'

// NOTIFICATION TYPES
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// BRANDING TYPES
export const GET_BRANDING_REQUEST = 'GET_BRANDING_REQUEST'
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS'
export const GET_BRANDING_FAILURE = 'GET_BRANDING_FAILURE'

//SERVICE APP LABEL
export const SERVICE_LABEL_DATA_REQUEST = 'SERVICE_LABEL_DATA_REQUEST'
export const SERVICE_LABEL_DATA_SUCCESS = 'SERVICE_LABEL_DATA_SUCCESS'
export const SERVICE_LABEL_DATA_FAILURE = 'SERVICE_LABEL_DATA_FAILURE'
