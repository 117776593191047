import httpClient from '../utils/axios'
import {
    handleServiceError,
    handleServiceResponse,
} from '../utils/serviceResponseHelper'
import appServiceConstants from '../constants/appservice.constants'

const getServiceLabelAsync = async () => {
    return httpClient
        .get(`${window.config.SERVICE_API_URL}/v1/ServiceLabel`)
        .then((response) => {
            return handleServiceResponse(response)
        })
        .catch((error) => handleServiceError(error))
}

export default {
    getServiceLabelAsync,
}
