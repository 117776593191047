import { createReducer } from '@reduxjs/toolkit'

import {
    EVENT_HISTORY_REQUEST,
    EVENT_HISTORY_SUCCESS,
    EVENT_HISTORY_EXTRA_INFO_SUCCESS,
    EVENT_HISTORY_FAILURE,
} from '../actions/types'

const initialState = {
    data: null,
    extraInfo: null,
    isLoading: false,
    error: null,
}

export default createReducer(initialState, {
    [EVENT_HISTORY_REQUEST]: (state) => {
        state.isLoading = true
        state.error = null
    },
    [EVENT_HISTORY_SUCCESS]: (state, action) => {
        state.data = action.payload;
        state.extraInfo = action.payload.map( data => {return {workflowTrackingEventId: data.workflowTrackingEventId, details : null}})
        state.isLoading = false;
        state.error = null;
    },
    [EVENT_HISTORY_EXTRA_INFO_SUCCESS]: (state, action) => {
        let index = state.extraInfo.findIndex(x => x.workflowTrackingEventId === action.payload.workflowTrackingEventId);
        state.extraInfo[index] = action.payload  
        state.error = null;
    },
    [EVENT_HISTORY_FAILURE]: (state, action) => {
        state.data = null
        state.isLoading = false
        state.error = action.payload
    },
})
