import React from 'react'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import PaperSubTitle from '../components/paperSubTitle'
import MainLayout from '../layouts/mainLayout'
import moment from 'moment'
import { STANDARD_DATE_FORMAT } from '../constants/dateformat.constants'
import { FormControl, TextField, makeStyles } from '@material-ui/core'
import GenericTable from '../components/genericTable'

import { useSelector } from 'react-redux'
import { displayNames } from '../constants/roles.constants'

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '10px 20px',
    },
    heading: {
        margin: '10px 20px',
    },
    section: {
        marginBottom: 30,
        marginTop: 30,
    },
}))

const ServiceDetails = () => {
    const serviceData = useSelector((state) => state.employeeInformation.data)
    const { serviceLabelData } = useSelector((state) => state.serviceLabel)

    const accessSchema = [
        {
            id: 'title',
            name: serviceLabelData ? serviceLabelData.accessLevelsHeading : '',
        }, // needs this ternary incase user hits F5 on page
        { id: 'primary', name: 'Primary' },
    ]

    const rolesSchema = [{ id: 'name', name: 'Role' }]

    const classes = useStyles()

    const accessLevels = serviceData
        ? serviceData.accessLevels.map((al) => {
              return {
                  title: al.title,
                  primary: al.primary ? 'Yes' : ' ',
              }
          })
        : []

    const roles = serviceData
        ? serviceData.roles.map((al) => ({
              name: displayNames[al.name],
          }))
        : []

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Service Details'} />
                <div className={classes.section}>
                    <PaperSubTitle title={'User Details'} />
                    <FormControl className={classes.formControl}>
                        <TextField
                            label={'Username'}
                            defaultValue={serviceData?.username || ''}
                            InputProps={{ readOnly: true }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label={'Last Logged In'}
                            defaultValue={moment(
                                serviceData?.lastLoginDate
                            ).format(STANDARD_DATE_FORMAT)}
                            InputProps={{ readOnly: true }}
                        />
                    </FormControl>
                </div>
                <PaperSubTitle title={'Access'} />
                <div className={classes.section}>
                    <GenericTable
                        schema={accessSchema}
                        data={accessLevels}
                    ></GenericTable>
                </div>
                <div className={classes.section}>
                    <GenericTable
                        schema={rolesSchema}
                        data={roles}
                    ></GenericTable>
                </div>
            </PaperLayout>
        </MainLayout>
    )
}

export default ServiceDetails
