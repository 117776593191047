import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { STANDARD_DATE_FORMAT } from '../constants/dateformat.constants'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import PaperSubTitle from '../components/paperSubTitle'
import { FormControl, TextField, makeStyles } from '@material-ui/core'
import MainLayout from '../layouts/mainLayout'

const useStyles = makeStyles(() => ({
    formControl: {
        margin: '10px 20px',
    },
    heading: {
        margin: '10px 20px',
    },
    section: {
        marginBottom: 30,
        marginTop: 30,
    },
}))

const EmployeeInformation = () => {
    const classes = useStyles()

    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )
    const { serviceLabelData } = useSelector((state) => state.serviceLabel)

    function getValueToDisplay(value, type) {
        try {
            switch (type) {
                case 'string':
                    return getString(value)
                case 'decimal':
                    return getDecimal(value)
                case 'date':
                    return getDate(value)
                case 'bool':
                    return getBool(value)
                default:
                    return ' '
            }
        } catch (err) {
            console.error(err)
            return ' ' //BLANK TO FORCE MATERIAL STYLE
        }
    }

    function getString(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        if (value.trim() === '') return ' '
        return value
    }

    function getDecimal(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        return value
    }

    function getDate(value) {
        if (!value) return ' ' //BLANK TO FORCE MATERIAL STYLE
        return moment(value).format(STANDARD_DATE_FORMAT)
    }

    function getBool(value) {
        return value ? 'Yes' : 'No'
    }

    function buildField(label, value, type) {
        return (
            <FormControl className={classes.formControl}>
                <TextField
                    label={label}
                    defaultValue={getValueToDisplay(value, type)}
                    InputProps={{ readOnly: true }}
                />
            </FormControl>
        )
    }

    function buildSection(section) {
        return (
            <div className={classes.section}>
                <PaperSubTitle title={section.name} />
                {section.fields
                    .slice()
                    .sort((a, b) => a.orderBy > b.orderBy)
                    .map((field) =>
                        buildField(
                            field.label,
                            userInformation[field.name],
                            field.dataType
                        )
                    )}
            </div>
        )
    }

    function buildContent() {
        return (
            <div className={classes.section}>
                {serviceLabelData.userProfileLabels
                    .slice()
                    .sort((a, b) => a.orderBy > b.orderBy)
                    .map((section) => buildSection(section))}
            </div>
        )
    }

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Employee Information'} />
                {userInformation && serviceLabelData && buildContent()}
                {userInformation && (
                    <>
                        <PaperSubTitle title={'Business Structure'} />
                        {userInformation.businessStructure
                            .slice()
                            .sort((a, b) => a.levelId > b.levelId)
                            .map((struct) =>
                                buildField(struct.name, struct.value, 'string')
                            )}
                    </>
                )}
            </PaperLayout>
        </MainLayout>
    )
}

export default EmployeeInformation
