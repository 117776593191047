import React, { useEffect } from 'react'

import MainLayout from '../layouts/mainLayout'
import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import GenericTable from '../components/genericTable'
import LoadingSpinner from '../components/loadingSpinner'
import { useSelector, useDispatch } from 'react-redux'

import { TextField } from '@material-ui/core'

import {
    fetchEventHistoryData,
    fetchEventHistoryExtraInfoData,
} from '../actions/eventhistory.actions'

const EventHistory = () => {
    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )
    const dispatch = useDispatch()
    const tableData = useSelector((state) => state.eventHistory.data)

    const extraInfoData = useSelector((state) => state.eventHistory.extraInfo)

    useEffect(() => {
        if (userInformation) {
            dispatch(fetchEventHistoryData(userInformation.userId))
        }
    }, [dispatch, userInformation])

    const schema = [
        { id: 'caseTrackingRecordId', name: 'Case Number' },
        { id: 'topicDescription', name: 'Topic' },
        { id: 'caseOwnerName', name: 'Case Owner' },
        { id: 'eventSetupTitle', name: 'Event Title' },
        { id: 'fromDateTime', name: 'From', type: 'Date' },
        { id: 'toDateTime', name: 'To', type: 'Date' },
    ]

    // this is to decide if the link to show the extra information should be shown
    const getIsExtraInfoAvailableHandler = (row) => {
        // TODO: need to include documents in this condition
        let isAvailable = false
        if (row.isFreeTextEnabled || row.isReasonsEnabled) {
            isAvailable = true
        }
        return isAvailable
    }

    const getExtraInfoDataHandlerAsync = async (row) => {
        // only get data if not already loaded
        console.log('extraInfoData', extraInfoData)
        const thisExtraInfos = extraInfoData.filter(
            (r) => r.workflowTrackingEventId === row.workflowTrackingEventId
        )
        if (thisExtraInfos && thisExtraInfos.length > 0) {
            let thisExtraInfo = thisExtraInfos[0]
            if (!thisExtraInfo.details) {
                await dispatch(
                    fetchEventHistoryExtraInfoData(row.workflowTrackingEventId)
                )
            }
        }
    }

    const getExtraInfoHandler = (row) => {
        // TODO: Notes,
        // Files uploaded, (can be clicked on to download)
        // Completed document service documents (can be clicked on to download)
        // Event reasons
        // Comments

        const thisExtraInfos = extraInfoData.filter(
            (r) => r.workflowTrackingEventId === row.workflowTrackingEventId
        )
        if (thisExtraInfos && thisExtraInfos.length > 0) {
            let thisExtraInfo = thisExtraInfos[0]

            let showSpinner = false

            // also need to add documents stuff here
            if (row.isReasonsEnabled) {
                if (!thisExtraInfo.details) {
                    // not got data yet so show spinner
                    showSpinner = true
                }
            }

            if (showSpinner) {
                return <LoadingSpinner />
            } else {
                // user comments
                let userComments
                if (row.isFreeTextEnabled) {
                    userComments = (
                        <TextField
                            id="outlined-multiline-static"
                            label="Comments"
                            multiline
                            variant="outlined"
                            style={{
                                width: '100%',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                            value={row.freeText}
                            InputProps={{ readOnly: true }}
                        />
                    )
                }

                // event setup reason
                let eventSetupReason = []
                if (row.isReasonsEnabled && row.eventReasons) {
                    eventSetupReason = row.eventReasons.map(
                        (reasonRow, rowIndex) => {
                            let reasonLabel = reasonRow.isPrimary
                                ? 'Primary Reason'
                                : 'Secondary Reason'
                            return (
                                <TextField
                                    key={rowIndex}
                                    id="outlined-multiline-static"
                                    label={reasonLabel}
                                    multiline
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                    value={reasonRow.reasonTitle}
                                    InputProps={{ readOnly: true }}
                                />
                            )
                        }
                    )
                }

                // document details TODO:
                if (1 === 2) {
                    thisExtraInfo.details.map((detail) => {
                        return <div>a={detail.testData}</div>
                    })
                }

                return (
                    <>
                        {userComments}
                        {eventSetupReason}
                    </>
                )
            }
        }
    }

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Event History'} />
                <GenericTable
                    schema={schema}
                    data={tableData}
                    defaultOrderBy="fromDateTime"
                    defaultRowsPerPage="25"
                    defaultOrder="desc"
                    getExtraInfo={getExtraInfoHandler}
                    getIsExtraInfoAvailable={getIsExtraInfoAvailableHandler}
                    getExtraInfoDataAsync={getExtraInfoDataHandlerAsync}
                />
            </PaperLayout>
        </MainLayout>
    )
}

export default EventHistory
