import React, { useEffect } from 'react'

import PaperLayout from '../components/paperLayout'
import PaperTitle from '../components/paperTitle'
import GenericTable from '../components/genericTable'
import { useSelector, useDispatch } from 'react-redux'

import { fetchEmployeeAbsenceData } from '../actions/employeeabsence.actions'
import MainLayout from '../layouts/mainLayout'

const EmployeeAbsence = () => {
    //TBC HOW TO GET THIS from query string
    const userInformation = useSelector(
        (state) => state.employeeInformation.data
    )

    const dispatch = useDispatch()
    const tableData = useSelector((state) => state.employeeAbsence.data)

    useEffect(() => {
        if (userInformation) {
            dispatch(fetchEmployeeAbsenceData(userInformation.userId))
        }
    }, [dispatch, userInformation])

    const schema = [
        { id: 'absenceId', name: 'Absence ID' },
        { id: 'startDate', name: 'From', type: 'Date' },
        { id: 'endDate', name: 'To', type: 'Date' },
        { id: 'type', name: 'Type' },
        { id: 'reason', name: 'Reason' },
    ]

    return (
        <MainLayout>
            <PaperLayout>
                <PaperTitle title={'Employee Absence'} />
                <GenericTable
                    schema={schema}
                    data={tableData}
                    defaultOrderBy="startDate"
                    defaultRowsPerPage="25"
                    defaultOrder="desc"
                />
            </PaperLayout>
        </MainLayout>
    )
}

export default EmployeeAbsence
