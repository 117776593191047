import { createReducer } from '@reduxjs/toolkit'

import {
    EMPLOYEE_CASE_REQUEST,
    EMPLOYEE_CASE_SUCCESS,
    EMPLOYEE_CASE_FAILURE,
} from '../actions/types'

const initialState = {
    data: null,
    isLoading: true,
    error: null,
}

export default createReducer(initialState, {
    [EMPLOYEE_CASE_REQUEST]: (state) => {
        state.isLoading = true
    },
    [EMPLOYEE_CASE_SUCCESS]: (state, action) => {
        state.data = action.payload
        state.isLoading = false
        state.error = false
    },
    [EMPLOYEE_CASE_FAILURE]: (state, action) => {
        state.data = null
        state.isLoading = false
        state.error = action.payload
    },
})
