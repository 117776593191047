import {
    GET_BRANDING_REQUEST,
    GET_BRANDING_SUCCESS,
    GET_BRANDING_FAILURE,
} from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    theme: null,
    isLoadingTheme: false,
    defaultTheme: {
        palette: {
            primary: {
                main: '#1c8d4c',
            },
            secondary: {
                main: '#116999',
            },
            headerBackground: {
                main: '#fff',
                contrastText: '#222',
            },
            navBackground: {
                main: '#f3f3f3',
            },
        },
    },
}

export default createReducer(initialState, {
    [GET_BRANDING_REQUEST]: (state, action) => {
        state.isLoadingTheme = true
    },
    [GET_BRANDING_SUCCESS]: (state, action) => {
        state.isLoadingTheme = false
        state.theme = action.payload
    },
    [GET_BRANDING_FAILURE]: (state, action) => {
        state.isLoadingTheme = false
        state.theme = state.defaultTheme
    },
})
