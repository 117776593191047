import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import moment from 'moment'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

const GenericTable = ({
    schema,
    data,
    defaultOrderBy,
    defaultRowsPerPage,
    defaultOrder,
    getExtraInfo,
    getIsExtraInfoAvailable,
    getExtraInfoDataAsync,
}) => {
    const classes = useStyles()
    const rowsPerPageOptions = [10, 25, 50]

    const [order, setOrder] = useState(getDefaultOrder())
    const [orderBy, setOrderBy] = useState(getDefaultOrderBy())
    const [rowsPerPage, setRowsPerPage] = useState(getDefaultRowsPerPage())
    const [currentPage, setCurrentPage] = useState(0)
    const [extraInfoExpanded, setExtraInfoExpanded] = useState(
        getInitialInfoExpanded()
    )

    // ------------------- Set up State ---------------------
    function getDefaultOrder() {
        // if set can be either asc or desc
        if (
            defaultOrder &&
            (defaultOrder === 'asc' || defaultOrder === 'desc')
        ) {
            return defaultOrder
        }
        return 'asc'
    }

    function getDefaultOrderBy() {
        // if not passed use first column defined in schema
        if (!defaultOrderBy) {
            return schema[0].id
        }

        // check if exists

        return defaultOrderBy
    }

    function getDefaultRowsPerPage() {
        // check if defaultRowsPerPage is valid and use as default
        if (defaultRowsPerPage) {
            const newDefaultRowsPerPage = parseInt(defaultRowsPerPage)
            if (Number.isInteger(newDefaultRowsPerPage)) {
                if (rowsPerPageOptions.indexOf(newDefaultRowsPerPage) > 0) {
                    return newDefaultRowsPerPage
                }
            }
        }
        return rowsPerPageOptions[0]
    }

    function getInitialInfoExpanded() {
        var extraInfoExpanded = []
        if (getExtraInfo) {
            if (data) {
                data.forEach(() => {
                    extraInfoExpanded.push(false) // set all to initially not expanded
                })
            }
        }
        return extraInfoExpanded
    }

    // ---------------------------------------------------------
    function createTableHead() {
        return (
            <TableRow>
                {schema.map((h) => (
                    <TableCell key={h.id}>
                        <TableSortLabel
                            active={orderBy === h.id}
                            direction={order}
                            onClick={() => handleSort(h.id)}
                        >
                            {h.name}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {getExtraInfo && (
                    // add extra column for extra info icon
                    <TableCell></TableCell>
                )}
            </TableRow>
        )
    }

    async function toggleExtraInfoAsync(rowIndex, row) {
        var thisExtraInfoExpanded = [...extraInfoExpanded]
        thisExtraInfoExpanded[rowIndex] = !thisExtraInfoExpanded[rowIndex] //toggle expanded status
        setExtraInfoExpanded(thisExtraInfoExpanded)

        console.log('toggleExtraInfoAsync')
        // optionally get extra info to display if not expanded
        if (getExtraInfoDataAsync && !extraInfoExpanded[rowIndex]) {
            await getExtraInfoDataAsync(row)
        }
        console.log('toggleExtraInfoAsync end')
    }

    const pointerOnHover = { cursor: 'pointer' }

    function createTableBody() {
        if (!data) {
            return null
        }
        var tableData = stableSort(data, getComparator(order, orderBy)).slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
        )

        var rows = []

        tableData.forEach((row, rowIndex) => {
            var cells = []
            var cellIndex = 0
            schema.forEach((prop) => {
                var columnValue = row[prop.id]
                if (prop.type) {
                    if (prop.type === 'Date') {
                        columnValue = moment(columnValue).isValid()
                            ? moment(row[prop.id]).format('DD/MM/YYYY')
                            : 'N/A'
                    }
                }

                cells.push(
                    <TableCell key={cellIndex}>
                        {columnValue || 'N/A'}
                    </TableCell>
                )
                cellIndex++
            })

            // optional extra  information to appear on next line - have toggle button on data line
            if (getExtraInfo) {
                var showExtraInfoExpander = true
                if (getIsExtraInfoAvailable) {
                    showExtraInfoExpander = getIsExtraInfoAvailable(row)
                }
                if (showExtraInfoExpander) {
                    cells.push(
                        <TableCell key={cellIndex}>
                            {extraInfoExpanded[rowIndex] ? (
                                <ExpandLessIcon
                                    style={pointerOnHover}
                                    onClick={() =>
                                        toggleExtraInfoAsync(rowIndex, row)
                                    }
                                />
                            ) : (
                                <ExpandMoreIcon
                                    style={pointerOnHover}
                                    onClick={() =>
                                        toggleExtraInfoAsync(rowIndex, row)
                                    }
                                />
                            )}
                        </TableCell>
                    )
                    cellIndex++
                }
            }

            rows.push(
                <TableRow hover key={rowIndex}>
                    {cells}
                </TableRow>
            )

            // optional extra information to appear on next line
            if (getExtraInfo) {
                rows.push(
                    <TableRow key={rowIndex + 100}>
                        <TableCell
                            key={rowIndex + 100}
                            style={{ padding: 0 }}
                            colSpan={schema.length + 1}
                        >
                            <Collapse in={extraInfoExpanded[rowIndex]}>
                                {getExtraInfo(row)}
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }
        })

        if (tableData.length === 0) {
            rows.push(
                <TableRow hover key="0">
                    <TableCell key="1" colSpan={schema.length}>
                        No data available
                    </TableCell>
                </TableRow>
            )
        }
        return rows
    }

    // ----------------------------------------------------------------------------
    function resetPage() {
        setExtraInfoExpanded(getInitialInfoExpanded())
    }

    const handleChangePage = (event, newPage) => {
        resetPage()
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        resetPage()
        setRowsPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const handleSort = (property) => {
        resetPage()
        if (property === orderBy) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        } else {
            setOrder('asc')
            setOrderBy(property)
        }
    }

    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>{createTableHead()}</TableHead>
                    <TableBody>{createTableBody()}</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    )
}

export default GenericTable
