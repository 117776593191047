import {
    STORE_USER,
    STORE_USER_ERROR,
    SERVICE_ACCESS_SUCCESS,
    SERVICE_ACCESS_REQUEST,
    SERVICE_ACCESS_FAILURE,
} from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    isAuthenticated: false,
    serviceHasAccess: null,
    isCheckingServiceAccess: false,
}

export default createReducer(initialState, {
    [STORE_USER]: (state, action) => {
        state.isAuthenticated = true
        state.user = action.payload
    },
    [STORE_USER_ERROR]: (state, action) => {
        state.isAuthenticated = false
    },
    [SERVICE_ACCESS_REQUEST]: (state, action) => {
        state.isCheckingServiceAccess = true
    },
    [SERVICE_ACCESS_SUCCESS]: (state, action) => {
        state.isCheckingServiceAccess = false
        state.serviceHasAccess = action.payload
    },
    [SERVICE_ACCESS_FAILURE]: (state, action) => {
        state.isCheckingServiceAccess = false
        state.serviceHasAccess = false
        state.isAuthenticated = false
    },
})
